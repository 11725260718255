import React, { useEffect, useRef } from 'react';
import HomeRouter from 'src/router/HomeRouter';
import {
  Drawer,
  Hidden,
  IconButton,
  Button,
  Dialog,
  Slide,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { LogoutContext } from 'src/components/LogoutWrapper';
import { useContext } from 'react';
import ArrowDownIcon from 'src/assets/svgIcons/ArrowDownIcon';
import { useDispatch, useSelector } from 'react-redux';
import StoreSelector from './components/StoreSelector';
import { TransitionProps } from '@material-ui/core/transitions';
import { SelectedStores } from 'src/components/SelectedStores';
import IntroModal from 'src/components/IntroModal';
import { get, startCase, uniqBy } from 'lodash';
import FoldersIcon from '../../assets/folders.svg';
import { datadogRum } from '@datadog/browser-rum';
import { Alert } from '@material-ui/lab';
import { storesApi } from 'src/services/user';
import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import AnalyticsIcon from '../../assets/pie-chart-alt.svg';
import BasketVerificationIcon from '../../assets/shopping-cart-8.svg';
import ProductsIcon from '../../assets/cube.svg';
import ItemsIcon from '../../assets/list-alt.svg';
import CashReportIcon from '../../assets/coins.svg';
import RevenueReportIcon from '../../assets/cash.svg';
import PriceActivationIcon from '../../assets/dollar-settings.svg';
import PromotionsIcon from '../../assets/percentage-ticket.svg';
import ScanReceiptIcon from '../../assets/percentage-ticket.svg';
import EODPoslogIcon from '../../assets/calender-alt-4.svg';
import SettlementsIcon from '../../assets/statistics.svg';
import HelpSupportIcon from '../../assets/question-circle.svg';
import CreateUserIcon from '../../assets/user-alt.svg';
import EmailReportIcon from '../../assets/send.svg';

const drawerWidth = 350;

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100%',
    display: 'flex',
    width: '100vw',
    background: '#fff',
  },
  appBar: {
    [theme.breakpoints.between('md', 'xl')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    width: '100%',
    overflow: 'hidden',
  },
  drawer: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    zIndex: -1,
  },
  drawerPermanant: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    position: 'absolute',
    left: 16,
    top: 32,
    backgroundColor: `${theme.palette.color_white.main} !important`,
    color: theme.palette.color_icon_grey.main,
    border: `1px solid ${theme.palette.border_color.main}`,
    borderRadius: 4,
    padding: '10px !important',
  },
  list: {
    padding: 0,
    flex: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  storeValue: {
    flex: 1,
    marginRight: 10,
    marginBottom: 4,
    height: '50px !important',
    minHeight: 50,
    borderRadius: 6,
    border: '1px solid #D1D5DB',
    background: '#fff',
    fontSize: 16,
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: '#637381',
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: 8,
    },
    textOverflow: 'ellipsis',
  },

  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2000,
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    background: '#00000080',
    zIndex: 2000,
    position: 'fixed',
  },
  loaderContent: {
    margin: 'auto',
    height: '100%',
    position: 'relative',
    top: '40%',
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2000,
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  listItem: {
    height: '64px',
  },
  listItemText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  drawerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  selectStore: { marginBottom: 8, fontSize: 13 },
  storeValueContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  refreshButton: {
    width: 40,
    height: 40,
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(51, 102, 255, 0.1)',
  },
  icon: { width: 20, height: 20 },
  listContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  listContentIcon: { width: 20, height: 20, marginRight: 20 },
  listContentText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  signUpContainer: {
    display: 'flex',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.color_light_grey.main,
    borderRadius: 20,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarIcon: { width: 25, height: 25 },
  userNameContainer: {
    display: 'flex',
    width: '100%',
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  userNameText: {
    fontSize: 18,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  userInitial: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlignLast: 'center',
    },
  },
  logoutButton: { alignSelf: 'flex-start', marginLeft: -8 },
  logoutButtonText: {
    color: theme.palette.color_text_grey.main,
    fontSize: 12,
  },
  marginTop: { marginTop: 20 },
  link: { textDecoration: 'none' },
  iconRoot: {
    '&:hover': {
      backgroundColor: theme.palette.color_white.main,
    },
  },
}));

const Home = () => {
  let hasMLSEOnlyStore = true;
  let hasCartersOnlyStore = true;
  let hasDDFOnlyStore = true;
  const classes = useStyles();
  const history = useHistory();
  const context = useContext(LogoutContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const intl = useIntl();
  const [openStoreModal, setOpenStoreModal] = React.useState(false);
  const { active_stores } = useSelector((state: any) => state.store);
  const [cookies] = useCookies();
  const [selectAllStores, setSelectAllStores] = useState(false);
  const {
    userData,
    permissions: { allow_analytics, allow_settlements, allow_users_create },
  } = useSelector((state: any) => state.user);
  const { introVisible } = useSelector((state: any) => state.screens);
  let hasScanAndGoStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.scan_and_go', false),
  );
  let hasClickAndCollectStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.click_and_collect', false),
  );
  let hasKioskStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.kiosk', false),
  );
  let hasMPosStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.mpos', false),
  );

  // TODO update following methods with cash_desk & cash_report keys as per BE implementation (GPP-5295)
  let hasCashDeskStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.cashier_kiosk', false),
  );
  let hasPromoEnabled = active_stores.some((store: any) =>
    get(store, 'features_applicable.promotion', false),
  );
  let hasCashReportStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.cash_desk', true),
  );
  let hasPriceActivationStores = active_stores.some((store: any) =>
    get(
      store,
      'features_applicable.price_activation_link',
      store.features_applicable.price_activation_link?.length > 0,
    ),
  );
  let priceActivationLink = active_stores.find((store: any) => {
    return store.features_applicable.price_activation_link;
  });
  const checkStoreTypes = () => {
    active_stores?.filter((store: any) => {
      if (store?.store_type !== 'MLSEStoreType') {
        hasMLSEOnlyStore = false;
      }
      if (store?.store_type !== 'CartersStoreType') {
        hasCartersOnlyStore = false;
      }
      if (store?.store_type !== 'DubaiDutyFreeStoreType') {
        hasDDFOnlyStore = false;
      }
    });
    return {
      MLSEStoreType: hasMLSEOnlyStore,
      CartersStoreType: hasCartersOnlyStore,
      DubaiDutyFreeStoreType: hasDDFOnlyStore,
    };
  };
  const showRevenueReport =
    checkStoreTypes()['MLSEStoreType'] ||
    checkStoreTypes()['DubaiDutyFreeStoreType']
      ? true
      : false;

  const showEmailReport = checkStoreTypes()['CartersStoreType'];
  const showItemReport = checkStoreTypes()['MLSEStoreType'] || checkStoreTypes()['DubaiDutyFreeStoreType'] ? true : false;
  const showIncompleteOrdersReport = checkStoreTypes()['DubaiDutyFreeStoreType'];

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');

  const menus = [
    {
      title: 'analytics',
      selected: false,
      linkTo: '/dashboard/analytics',
      default: 'Analytics',
      icon: AnalyticsIcon,
      visible: allow_analytics,
    },
    {
      title: 'products',
      selected: false,
      linkTo: false,
      default: 'Products',
      icon: ProductsIcon,
      visible: true,
      children: [
        {
          title: 'click&Collect',
          selected: true,
          linkTo: '/dashboard/click_and_collect',
          default: 'Click & Colect',
          visible: hasClickAndCollectStores,
        },
        {
          title: 'orders',
          selected: false,
          linkTo: '/dashboard/orders',
          default: 'Scan & Go orders',
          visible: hasScanAndGoStores,
        },
        {
          title: 'cashDesk',
          selected: false,
          linkTo: '/dashboard/cashDesk',
          default: 'CashDesk',
          visible: hasCashDeskStores,
        },
        {
          title: 'kiosk',
          selected: false,
          linkTo: '/dashboard/kiosk',
          default: 'Kiosk',
          visible: hasKioskStores,
        },
        {
          title: 'mPOS',
          selected: false,
          linkTo: '/dashboard/mpos',
          default: 'mPOS',
          visible: hasMPosStores,
        },
      ],
    },
    {
      title: 'cashReport',
      selected: false,
      linkTo: '/dashboard/cashReport',
      default: 'Cash Report',
      icon: CashReportIcon,
      visible: hasCashDeskStores,
    },
    {
      title: 'priceActivation',
      selected: false,
      linkTo: '/dashboard/priceActivation',
      default: 'Price Activation',
      icon: PriceActivationIcon,
      type: 'priceActivation',
      visible: hasPriceActivationStores,
    },
    {
      title: 'scanOrder',
      selected: false,
      linkTo: '/dashboard/scan',
      default: 'Scan Receipt',
      icon: ScanReceiptIcon,
      visible: true,
    },
    {
      title: 'verification',
      selected: false,
      linkTo: '/dashboard/verification',
      default: 'Basket Verification',
      icon: BasketVerificationIcon,
      visible: true,
    },
    {
      title: 'promotions',
      selected: false,
      linkTo: '/dashboard/promos',
      default: 'Promotions',
      icon: PromotionsIcon,
      visible: hasPromoEnabled,
    },
    {
      title: 'userCreation',
      selected: false,
      linkTo: '/dashboard/createUsers',
      default: 'Create User Information',
      icon: CreateUserIcon,
      visible: allow_users_create,
    },
    {
      title: 'settlements',
      selected: false,
      linkTo: '/dashboard/settlements',
      default: 'Settlements',
      icon: SettlementsIcon,
      visible: allow_settlements,
    },
    {
      title: 'report',
      selected: false,
      linkTo: '/dashboard/revenue',
      default: 'Revenue Report',
      icon: RevenueReportIcon,
      visible: showRevenueReport,
    },
    {
      title: 'report',
      selected: false,
      linkTo: '/dashboard/emailReport',
      default: 'Email Report',
      icon: EmailReportIcon,
      visible: showEmailReport,
    },
    {
      title: 'itemReport',
      selected: false,
      linkTo: '/dashboard/itemReport',
      default: 'Item Report',
      icon: ItemsIcon,
      visible: showItemReport,
    },
    {
      title: 'incompleteOrders',
      selected: false,
      linkTo: '/dashboard/incompleteOrders',
      default: 'Incomplete Orders Report',
      icon: FoldersIcon,
      visible: showIncompleteOrdersReport,
    },
    {
      title: 'helpDesk',
      selected: false,
      linkTo: '/dashboard/helpDesk',
      default: 'Help & Support',
      icon: HelpSupportIcon,
      type: 'newTab',
      visible: true,
    },
  ];

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    if (localStorage.getItem('persist:root')) {
      localStorage.removeItem('persist:root');
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'sessionExpired' }) + ". Please refresh your screen.");
      setTimeout(() => {
        context.logoutUser();
      }, 4000);
    }
  });
  useEffect(() => {
    let timer: any = null;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    try {
      let maskedemail;
      if (
        !userData?.email?.includes('mishipay') ||
        !userData?.username?.includes('mishipay')
      ) {
        let email = userData.email.split('@');
        maskedemail = `****@${email[1]}`;
      }
      datadogRum.setUser({
        id: userData.user_id,
        username: userData.username,
        email: maskedemail,
      });
    } catch (e) {
      console.log('missing userdata', e);
    }
  }, [userData]);

  const handleOpenStoreModal = async () => {
    setSpinner(true);
    await refreshStores();
    setSpinner(false);
    if (window.innerWidth <= 768) {
      drawerOpen && setDrawerOpen(false)
      history.push({
        pathname: `/dashboard/store-selector`})
    } else {
      setOpenStoreModal(true);
    }
  };
  const handleCloseStoreModal = (event: object, reason: string) => {
    drawerOpen && setDrawerOpen(false)
    if (reason !== 'backdropClick') setOpenStoreModal(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const lovesNamingConvention = (stores: any = selectAllStores) => {
    if (stores.length > 50) {
      return stores;
    }
    stores?.filter((store: any) => {
      if (store?.store_type === 'LovesStoreType') {
        store = editLovesStoreName(store);
      }
    });
    return stores;
  };
  const editLovesStoreName = (store: any) => {
    if (store.address_json?.length) {
      let address = JSON.parse(store.address_json);
      let clonedObject = { ...address };
      clonedObject = {
        ...clonedObject,
        retailer: `${store.retailer_store_id} - ${address.city}, ${address.state}`,
      };
      return clonedObject;
    }
    return store;
  };
  const refreshStores = () =>
    storesApi.query({ v1: true, token: cookies.app_token, show_demo: true });
  const { isLoading, mutateAsync: getStores } = useMutation(refreshStores, {
    onError: (serverError: any) => {
      setSpinner(false);
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: successData => {
      let storesData = get(successData, 'data.data', []).filter((item: any) => {
        return !item.retailer.split(' ').includes('All');
      });
      storesData = lovesNamingConvention(storesData);
      let regions = uniqBy(
        storesData
          .map((item: any) => {
            if (item.region) {
              return {
                title: item.region,
                value: item.region,
              };
            }
          })
          .filter((item: any) => item && item.value),
        'value',
      );
      let retailers = uniqBy(
        storesData
          .map((item: any) => {
            if (item.store_type) {
              return {
                title: startCase(item.store_type.replace('StoreType', '')),
                value: item.store_type,
              };
            }
          })
          .filter((item: any) => item && item.value),
        'value',
      );
      dispatch({
        type: 'SET_STORES',
        payload: lovesNamingConvention(storesData),
      });

      dispatch({
        type: 'SET_REGIONS',
        payload: regions,
      });
      dispatch({
        type: 'SET_RETAILERS',
        payload: retailers.sort((a: any, b: any) => {
          a = a.title.toLowerCase();
          b = b.title.toLowerCase();
          return a < b ? -1 : a > b ? 1 : 0;
        }),
      });
      setSpinner(false);
      setOpenStoreModal(true);
    },
  });

  const selectedTabContent = (item: any, index: any) => {
    switch (item.type) {
      case 'grafana': {
        return (
          <Link key={index} to={item.linkTo} className={classes.link}>
            <NavItem menu={item.default} img={item.icon} link={item.linkTo} visible={item.visible} />
          </Link>
        );
      }
      case 'newTab': {
        return (
          <>
            <Divider />
            <NavItem menu={item.default} img={item.icon} link={item.linkTo} visible={item.visible} itemtype={item.type} />
          </>
        );
      }
      case 'priceActivation': {
        return (
          <>
            <NavItem
              menu={item.default}
              img={item.icon}
              itemtype={item.type}
              link={item.linkTo}
              visible={item.visible}
            />
          </>
        );
      }
      default: {
        if (item.children) {
          return (
            <NavItem
              menu={item.default}
              img={item.icon}
              link={item.linkTo}
              visible={item.visible}
              submenu
            >
              {item.children.map((childItem: any, index: number) => {
                return childItem.visible ? (
                  <Link
                    key={index}
                    to={childItem.linkTo}
                    className={classes.link}
                    onClick={() => {
                      drawerOpen && setDrawerOpen(false);
                    }}
                  >
                    <DropdownItem
                      menu={childItem.default}
                      img={childItem.icon}
                      link={childItem.linkTo}
                    />
                  </Link>
                ) : null;
              })}
            </NavItem>
          );
        }
        return (
          <Link key={index} to={item.linkTo} className={classes.link}>
            <NavItem menu={item.default} img={item.icon} link={item.linkTo} visible={item.visible} />
          </Link>
        );
      }
    }
  };

  const menuNav = type => {
    if (type === 'priceActivation') {
      window.open(
        priceActivationLink.features_applicable.price_activation_link,
        '_blank',
      );
    }
    if (type === 'newTab') {
      window.open(
        'https://mishipay.atlassian.net/servicedesk/customer/portals',
        '_blank',
      );
    }
  };

  const NavItem = ({
    menu,
    img = '',
    link,
    visible,
    submenu = false,
    itemtype = false,
    children = [<></>],
  }) => {
    const [dropdownOpen, setDropdownOpen] = useState(true);
    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close if the esc key is pressed
    useEffect(() => {
      const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
        setDropdownOpen(false);
      };
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
    });

    return visible || children ? (
      <li className="relative">
        <div
          ref={trigger}
          onClick={(event) => {
            event.target['textContent'] === 'Products' &&
              setDropdownOpen(!dropdownOpen);
            event.target['textContent'] != 'Products' && drawerOpen && setDrawerOpen(false);
            menuNav(itemtype);
          }}
          className={`
            ${
              window.location.pathname.includes(link)
                ? '!text-primary !border-primary bg-primary-5'
                : 'text-[#637381] border-transparent hover:bg-[#f5f7ff]'
            }
            ${dropdownOpen ? '!text-primary !border-primary' : ''} 
          
          capitalize font-[500] text-[16px] no-underline relative flex items-center border-r-4 py-[10px] pl-[35px] pr-[30px] duration-200 hover:border-primary hover:!bg-primary-5 hover:text-primary`}
        >
          <img className={`${classes.listContentIcon} ${window.location.pathname.includes(link) ? '!text-primary' : ''}`} src={img} />
          {menu}
          {submenu && (
            <span
              className={`${
                dropdownOpen === true ? 'rotate-0' : 'rotate-180'
              } absolute right-10 top-1/2 -translate-y-1/2`}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                className="fill-current"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.5899 13.0899C15.2645 13.4153 14.7368 13.4153 14.4114 13.0899L10.0006 8.67916L5.58991 13.0899C5.26447 13.4153 4.73683 13.4153 4.41139 13.0899C4.08596 12.7645 4.08596 12.2368 4.41139 11.9114L9.41139 6.9114C9.73683 6.58596 10.2645 6.58596 10.5899 6.9114L15.5899 11.9114C15.9153 12.2368 15.9153 12.7645 15.5899 13.0899Z"
                ></path>
              </svg>
            </span>
          )}
        </div>
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className={`${dropdownOpen === true ? 'block' : 'hidden'} `}
        >
          <ul className="pl-0">{children}</ul>
        </div>
      </li>
    ) : null;
  };

  const DropdownItem = ({ link = '', menu, img = '' }) => {
    return (
      <li>
        <div
          className={`${
            window.location.pathname.includes(link)
              ? 'bg-primary-5 !text-primary border-primary !border-r-4 border-l-0 border-t-0 border-b-0'
              : 'text-[#637381]'
          }
          flex border-r-4 border-transparent py-[10px] pl-[65px] duration-200 hover:text-primary hover:bg-[#f5f7ff] hover:border-primary capitalize font-[500] text-[16px] no-underline`}
        >
          {menu}
        </div>
      </li>
    );
  };

  const Divider = () => {
    return <div className="mx-[35px] my-[15px] h-[1px] bg-[#DFE4EA]"></div>;
  };

  const drawerContent = () => (
    <>
      <Box className={classes.drawerContainer}>
        <Box className={classes.storeValueContainer}>
          <Button
            variant="outlined"
            className={classes.storeValue}
            onClick={handleOpenStoreModal}
            endIcon={<ArrowDownIcon />}
            style={{ marginBottom: 4 }}
          >
            Select Store
          </Button>
          <SelectedStores />
        </Box>
      </Box>

      {/* Select store end */}

      <div className="flex h-screen w-full flex-col justify-between overflow-y-scroll bg-white shadow-testimonial-6">
        <div>
          <nav className="pt-[30px]">
            <ul className="pl-0">
              {menus.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  {item?.visible ? selectedTabContent(item, index) : <></>}
                </React.Fragment>
              ))}
            </ul>
          </nav>
        </div>
        <div className="py-[30px] pl-[35px]">
          <div className="flex items-center">
            <div className={`mr-4 h-[50px] w-full max-w-[50px] rounded-full bg-primary ${classes.userInitial}`}>
              <span className="text-[#ffffff] font-[600] text-[20px] leading-[50px] capitalize">
                {userData.username.charAt(0)}
              </span>
            </div>
            <div>
              <h6 className="mb-0 text-left text-[#111928] text-[16px] font-[500] capitalize">
                {userData.username}
              </h6>
              <p
                className="mb-0 text-sm text-[#637381] text-[14px] font-[400] text-left"
                onClick={() => {
                  context.logoutUser();
                }}
              >
                Log Out
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      <Box className={classes.root}>
      {spinner && (
          <Box className={classes.loaderContainer}>
            <Box className={classes.loaderContent}>
              <CircularProgress color="primary" size={50} />
            </Box>
          </Box>
        )}
        <Hidden only={['md', 'lg', 'xl']} implementation="css">
          <Drawer
            variant="temporary"
            className={classes.drawer}
            classes={{ paper: classes.drawer }}
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            {drawerContent()}
          </Drawer>
        </Hidden>
        <Hidden only={['xs', 'sm']} implementation="css">
          <Drawer
            variant="permanent"
            className={classes.drawerPermanant}
            classes={{ paper: classes.drawerPermanant }}
            anchor="left"
          >
            {drawerContent()}
          </Drawer>
        </Hidden>
        <Box className={classes.appBar}>
          <Hidden only={['md', 'lg', 'xl']} implementation="css">
            <IconButton
              className={classes.menu}
              classes={{ root: classes.iconRoot }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <HomeRouter />
        </Box>
      </Box>
      {openStoreModal && window.innerWidth > 768 && (
        <Dialog
          keepMounted
          disableEnforceFocus
          disableEscapeKeyDown
          open={openStoreModal}
          TransitionComponent={Transition}
          scroll="paper"
          onClose={handleCloseStoreModal}
          fullWidth={true}
        >
          <StoreSelector onClose={handleCloseStoreModal} />
        </Dialog>
      )}
      {introVisible && (
        <Dialog
          disableEnforceFocus
          disableEscapeKeyDown
          open={introVisible}
          TransitionComponent={Transition}
          scroll="paper"
          onClose={() => {
            dispatch({
              type: 'TOGGLE_INTRO',
              payload: false,
            });
          }}
          fullWidth={true}
        >
          <IntroModal
            onClose={() => {
              dispatch({
                type: 'TOGGLE_INTRO',
                payload: false,
              });
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default Home;
