import { memo } from 'react';
import { Card, Box, Typography, Button, Divider, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash';
import {
  currencyFormat,
  dateTimeFormat,
  getOrderCount,
  getStoreByID,
  goToOrderDetails,
  orderStatus,
  verificationReasons,
} from 'src/utils';
import { useHistory } from 'react-router-dom';
import AlertIcon from '../../assets/svgIcons/AlertIcon';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 'auto !important',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '680px',
    },
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      margin: 10,
    },
    padding: 10,
    overflow: 'auto',
    borderRadius: 4,
    background: 'var(--White-White, #FFF)',
    boxShadow: '0px 1px 3px 0px rgba(166, 175, 195, 0.40)',
  },
  header: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 20,
      marginRight: 20,
      marginTop: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  body: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    marginLeft: 24,
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      padding: '10px 0'
    },
  },
  order: {
    fontWeight: 600,
    fontSize: '20px',
    color: '#111928',
    alignSelf: 'flex-start',
    marginBottom: 6,
  },
  date: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#637381',
    alignSelf: 'flex-start',
  },
  storeName: {
    fontSize: '14px',
    color: '#111928',
    fontWeight: 500,
    alignSelf: 'flex-start',
    marginBottom: 4,
    textAlign: 'left',
  },
  orderDateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      flex: 1,
    },
  },
  button: {
    height: 40,
    fontWeight: 'bold',
    backgroundColor: (props: any) => `${theme.palette[props.color].background}`,
    border: (props: any) => `solid 1px ${theme.palette[props.color].main}`,
    textTransform: 'none',
    color: (props: any) =>
      props.variant === 'outlined'
        ? theme.palette[props.color].main
        : theme.palette.color_white.main,
    fontSize: 16,
    borderRadius: 6,
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  buttonContainer: {
    display: 'flex',
    flex: 0.5,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      textAlign: 'left',
      margin: '5px 10px 0px 10px',
      paddingBottom: 10,
      borderBottom: '1px solid #DFE4EA',
      justifyContent: 'flex-start',
    },
  },
  bodyItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 30,
    [theme.breakpoints.down('xs')]: {
      width: '30%',
    },
    width: '20%',
  },
  item1: {
    width: 100,
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    fontSize: 14,
    color: '#637381',
    fontWeight: 400,
  },
  item2: {
    fontSize: 14,
    color: '#111928',
    fontWeight: 500,
    textAlign: 'left',
    whiteSpace: 'break-spaces',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '80%',
  },
  issueContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  issue: {
    textTransform: 'none',
    alignSelf: 'flex-start',
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: 16,
    },
    color: theme.palette.warning.main,
  },
}));

const itemList = [
  {
    title: <FormattedMessage id="total" defaultMessage="Total" />,
  },
  {
    title: <FormattedMessage id="items" defaultMessage="Items" />,
  },
  {
    title: <FormattedMessage id="customer" defaultMessage="Customer" />,
  },
  {
    title: (
      <FormattedMessage
        id="transactionId"
        defaultMessage="Transaction ID Poslog"
      />
    ),
  },
  {
    title: <FormattedMessage id="sco Id" defaultMessage="Kiosk ID" />,
  },
];

const OrdersCard = ({ item }: any) => {
  const {
    o_id,
    date,
    order_status,
    cc_order_status,
    discounted_price,
    currency,
    serial_number_required,
    store_name,
    store_id,
    transaction_id_poslog,
    order_id,
    risk_engine_verification_reason,
    risk_engine_verification_required,
  } = item;
  const sco_id = item.extra_data.retailer_device_id;
  const customer = `${get(item, 'customer.first_name', '')} ${get(
    item,
    'customer.last_name',
    '',
  )}`;
  const itemValue = [
    {
      // value: `${getCurrency(currency)} ${updateDecimals(
      //   discounted_price,
      // ).toFixed(2)}`,
      value: `${new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyFormat(currency),
      }).format(discounted_price)}`,
    },
    { value: getOrderCount(item) },
    { value: customer },
    { value: transaction_id_poslog },
    { value: sco_id },
  ];
  let isPartialRefund =
    get(item, 'non_refunded_items', []).length > 0 &&
    get(item, 'refunded_items', []).length > 0;
  const { icon, status, color, variant } =
    order_status === 'verified' && cc_order_status === 'collected'
      ? orderStatus('verified_collected', isPartialRefund, true)
      : order_status === 'completed' && serial_number_required
      ? orderStatus('serial_number_required', isPartialRefund, true)
      : orderStatus(order_status, isPartialRefund, true);
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const store = getStoreByID(active_stores, store_id);
  const history = useHistory();

  const classes = useStyles({ color, variant });
  const intl = useIntl();
  return (
    <Card
      className={classes.root}
      onClick={() => {
        goToOrderDetails({
          order_id,
          type: 'orders',
          from: 'orders',
          history,
        });
      }}
    >
      <Box className={classes.header}>
        <Box className={classes.orderDateTimeContainer}>
          <Typography className={classes.order}>{`#${o_id}`}</Typography>
          <Typography className={classes.date}>
            {date
              ? `${moment(date).format(dateTimeFormat(item.store_type))} ( UTC
            ${moment(date).utc().format('HH:mm')} )`
              : ''}
          </Typography>
          <Typography className={classes.storeName}>{store_name} {store.rfid === 1 ? '(RFID)' : ''}</Typography>
        </Box>
        <Hidden only={['xs']}>
          <Box className={classes.buttonContainer}>
            <Button
              startIcon={icon}
              className={classes.button}
              variant={variant}
            >
              {status}
            </Button>
          </Box>
        </Hidden>
      </Box>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <Box className={classes.buttonContainer}>
          <Button startIcon={icon} className={classes.button} variant={variant}>
            {status}
          </Button>
        </Box>
      </Hidden>
      {risk_engine_verification_required && order_status === 'completed' && (
        <Box className={classes.issueContainer}>
          <Button startIcon={<AlertIcon />} className={classes.issue}>
            {verificationReasons(risk_engine_verification_reason, null, intl)}
          </Button>
        </Box>
      )}
      <Divider light />
      <Box className={classes.body}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {itemList.map((item: any, index: number) => {
            return (
              itemValue[index].value && (
                <Box className={classes.bodyItem} key={index}>
                  <Typography className={classes.item1}>
                    {item.title}
                  </Typography>
                  <Typography
                    className={`${classes.item2} ${
                      index === 2 ? classes.ellipsis : ''
                    }`}
                  >
                    {itemValue[index].value}
                  </Typography>
                </Box>
              )
            );
          })}
        </Box>
      </Box>
    </Card>
  );
};

export default memo(OrdersCard);
