import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  Box,
  List,
  ListItem,
  IconButton,
  Typography,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  InputAdornment,
  TextField,
  Checkbox,
  Snackbar,
  Hidden,
  CircularProgress,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from 'src/assets/svgIcons/SearchIcon';
import CloseIcon from 'src/assets/svgIcons/CloseIcon';
import ArrowDownIcon from 'src/assets/svgIcons/ArrowDownIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { remove, uniqBy, startCase, get } from 'lodash';
import SystemCheckbox from 'src/components/SystemCheckbox';
import { useMutation } from 'react-query';
import { storesApi } from 'src/services/user';
import { useCookies } from 'react-cookie';
import { Alert } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { eventBus, getRequiredStores } from '../../../../utils';
import { useHistory } from 'react-router-dom';

enum IncludeTypes {
  DEMO = 'DEMO',
  INACTIVE = 'INACTIVE',
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      background: '#fff',
      width: '100%',
    },
  },
  list: { padding: 0, flex: 1 },
  listItem: { height: '64px' },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  backButton: {
    flex: 1,
  },
  headerTitle: {
    flex: 4,
    textAlign: 'left',
    alignContent: 'center',
    fontWeight: 600,
    fontSize: 20,
  },
  refreshBtn: {
    flex: 1,
  },
  dialogTitle: {
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  listItemText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  listItemTextDemo: {
    color: theme.palette.color_text_grey.main,
    fontWeight: 'bold',
    fontSize: 15,
    marginLeft: 8,
  },
  drawerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  selectStore: { marginBottom: 8, fontSize: 13 },
  storeValueContainer: { display: 'flex', width: '100%' },
  refreshButton: {
    width: 40,
    height: 40,
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(51, 102, 255, 0.1)',
  },
  icon: { width: 20, height: 20 },
  listContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  listContentIcon: { width: 20, height: 20, marginRight: 20 },
  listContentText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  dialogContent: {
    minHeight: 400,
    maxHeight: 400,
    margin: '0 25px 10px',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '0 0px 10px',
      borderBottom: 0,
    },
  },
  actionContainer: {
    width: '100%',
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-flex',
    },
  },
  cancelButton: {
    marginRight: 8,
    color: '#000',
    textTransform: 'none',
    borderRadius: 6,
    border: '1px solid #111928',
    width: 100,
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  selectedStoresButton: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  staticFooter: {
    position: 'sticky',
    bottom: 0,
    background: '#fff',
    paddingTop: 8,
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      width: '100%',
    },
  },
  clear: {
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
      width: 'auto !important',
    },
  },
  clearButton: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
    },
  },
  '& .MuiFormControl-fullWidth': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .MuiButton-startIcon': {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  refresh: {
    borderRadius: 6,
    border: '1px solid #3758F9',
    background: '#FFF',
    height: 50,
    fontSize: 16,
    textTransform: 'none',
    width: 100,
    [theme.breakpoints.down('sm')]: {
      border: 0,
      width: 35,
    },
  },
  filterLayoutRow: { display: 'flex', flexDirection: 'row' },
  filterLayoutCol: {
    display: 'inline-flex',
    width: '100%',
  },
  includeStoresContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  includeText: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginLeft: 9,
      marginTop: 15,
    },
  },
  regionInput: { marginTop: 16, maxHeight: 100, width: '100%' },
  retailerInput: {
    marginTop: 16,
    marginRight: 8,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2000,
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const StoreSelector = ({ onClose }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const intl = useIntl();

  const {
    stores,
    active_stores = [],
    retailers,
    regions,
    selected_regions = [],
    selected_retailers = [],
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);
  const {
    userData: { email, username },
  } = useSelector((state: any) => state.user);

  const retailersState = useMemo(
    () => [
      { value: 'AllRetailers', title: 'All Retailers', type: 'All' },
      ...retailers.map((item: any) => ({ ...item, type: 'single' })),
    ],
    [retailers],
  );

  const regionState = useMemo(
    () => [
      { value: 'AllRegions', title: 'All Regions', type: 'All' },
      ...regions.map((item: any) => ({ ...item, type: 'single' })),
    ],
    [regions],
  );

  const [availableRetailers, setRetailers] = useState(retailersState);
  const [availableRegions, setRegions] = useState(regionState);
  const [selectedRetailers, setSelectedRetailers] =
    useState(selected_retailers);
  const [selectedRegions, setSelectedRegion] = useState(selected_regions);
  const [selectedStores, setSelectedStores] = useState(active_stores);
  const [selectAllStores, setSelectAllStores] = useState(false);
  const [data, setData] = useState(stores);
  const [input, setInput] = useState('');
  const [toast, showToast] = useState(false);
  const [includeDemoStores, setIncludeDemoStores] =
    useState(include_demo_stores);
  const [includeInactiveStores, setIncludeInactiveStores] = useState(
    include_inactive_stores,
  );
  const [errorMessage, setErrorMessage] = useState('Error');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(15);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const isSmallScreen = window.innerWidth <= 768;

  const handleClose = () => {
    if (isSmallScreen) {
      history.goBack();
    } else {
      onClose();
    }
  };

  const handleLoadMore = () => {
    setItemsToShow(prev => prev + 15);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          handleLoadMore();
        }
      },
      { threshold: 1.0 },
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
      observer.disconnect(); // Ensure the observer is disconnected
    };
  }, [loadMoreRef]);

  const handleCloseToast = () => showToast(false);

  const executeSearch = (item: any, query: any) =>
    item.retailer.toLowerCase().includes(query.toLowerCase());

  const filterDemoAndInactiveStores = useCallback(
    () => getRequiredStores(stores, includeDemoStores, includeInactiveStores),
    [stores, includeDemoStores, includeInactiveStores],
  );

  const filterStoresByRetailer = useCallback(
    (retailers: any = selectedRetailers) =>
      retailers.length
        ? filterDemoAndInactiveStores().filter((store: any) =>
            retailers.some((item: any) =>
              store?.store_type
                ?.toLowerCase()
                .includes(item.value?.toLowerCase()),
            ),
          )
        : filterDemoAndInactiveStores(),
    [filterDemoAndInactiveStores, selectedRetailers],
  );

  const filterStoresByRegion = useCallback(
    (regions: any = selectedRegions, retailers: any = selectedRetailers) =>
      regions.length
        ? filterStoresByRetailer(retailers).filter((store: any) =>
            regions.some((item: any) =>
              store?.region?.toLowerCase().includes(item.value?.toLowerCase()),
            ),
          )
        : filterStoresByRetailer(retailers),
    [filterStoresByRetailer, selectedRegions],
  );

  const lovesNamingConvention = (stores: any = selectAllStores) => {
    if (stores.length > 50) {
      return stores;
    }
    stores?.filter((store: any) => {
      if (store?.store_type === 'LovesStoreType') {
        store = editLovesStoreName(store);
      }
    });
    return stores;
  };
  const editLovesStoreName = (store: any) => {
    if (store.address_json?.length) {
      const address = JSON.parse(store.address_json);
      return {
        ...address,
        retailer: `${store.retailer_store_id} - ${address.city}, ${address.state}`,
      };
    }
    return store;
  };

  const onChangeText = (e: any) => {
    const query = e.target.value;
    setInput(query);
    setSelectAllStores(false);
    let textFilteredStores = filterStoresByRegion().filter((item: any) =>
      executeSearch(item, query),
    );
    setData([...textFilteredStores]);
    setSelectedStores([...textFilteredStores]);
  };

  const clearAll = () => {
    setSelectAllStores(false);
    setSelectedStores([]);
    setSelectedRetailers([]);
    setSelectedRegion([]);
  };

  const onIncludeToggle = (type: IncludeTypes) => {
    clearAll();
    const filterStores = getRequiredStores(
      stores,
      type === IncludeTypes.DEMO ? !includeDemoStores : includeDemoStores,
      type === IncludeTypes.INACTIVE
        ? !includeInactiveStores
        : includeInactiveStores,
    );

    const filterRetailers = uniqBy(
      filterStores
        .map(
          (item: any) =>
            item.store_type && {
              title: startCase(item.store_type.replace('StoreType', '')),
              value: item.store_type,
            },
        )
        .filter((item: any) => item && item.value),
      'value',
    );
    setRetailers(filterRetailers);
    if (type === IncludeTypes.DEMO) setIncludeDemoStores(!includeDemoStores);
    else if (type === IncludeTypes.INACTIVE)
      setIncludeInactiveStores(!includeInactiveStores);
  };

  const getSelectedRetailer = (
    event: any,
    value: any,
    reason: any,
    details?: any,
  ) => {
    if (details?.option?.type === 'All' || !details) {
      if (reason === 'select-option') {
        if (selectedRetailers.length) {
          setSelectedRetailers([]);
          setSelectedStores([]);
          setSelectedRegion([]);
          return [];
        } else {
          setSelectedStores(stores);
          setSelectAllStores(true);
          return availableRetailers;
        }
      } else if (reason === 'remove-option' || reason === 'clear') {
        setSelectedStores([]);
        setSelectedRegion([]);
        return [];
      }
    } else if (reason === 'select-option') {
      if (
        availableRetailers.length - [...selectedRetailers, value].length ===
        1
      ) {
        setSelectedStores(filterStores(availableRetailers, selectedRegions));
        return availableRetailers;
      } else {
        setSelectedStores(filterStores(value, selectedRegions));
        return value;
      }
    } else if (reason === 'remove-option') {
      if (availableRetailers.length === selectedRetailers.length) {
        return remove([...value], (i: any) => i.type !== 'All');
      } else {
        setSelectedStores(
          selectedStores.filter(
            (item: any) => item.store_type !== details.option.value,
          ),
        );
        return value;
      }
    } else return value;
  };

  const onRetailerChange = (
    event: any,
    value: any,
    reason: any,
    details?: any,
  ) => {
    event.stopPropagation();
    setSelectAllStores(false);
    const retailerValue = getSelectedRetailer(event, value, reason, details);
    setSelectedRetailers(retailerValue);
    if (retailerValue.length) {
      const regions = filterStoresByRetailer(retailerValue).map(
        (store: any) => ({
          value: store.region,
          title: store.region,
          type: 'single',
        }),
      );
      setRegions(
        uniqBy(
          [
            { value: 'AllRegions', title: 'All Regions', type: 'All' },
            ...regions,
          ],
          'value',
        ),
      );
    } else setRegions(regionState);
    setSelectedRegion([]);
  };

  const onRegionChange = (
    event: any,
    value: any,
    reason: any,
    details?: any,
  ) => {
    event.stopPropagation();
    setSelectAllStores(false);
    if (details?.option?.type === 'All' || !details) {
      if (reason === 'select-option') {
        selectedRegions.length
          ? setSelectedRegion([])
          : setSelectedRegion(availableRegions);
        setSelectedStores(filterStoresByRegion(availableRegions));
      } else if (reason === 'remove-option' || reason === 'clear') {
        setSelectedRegion([]);
        setSelectedStores(filterStoresByRetailer());
      }
    } else if (reason === 'select-option') {
      if (availableRegions.length - [...selectedRegions, value].length === 1) {
        setSelectedRegion(availableRegions);
        setSelectedStores(filterStores(selectedRetailers, availableRegions));
      } else {
        setSelectedRegion(value);
        setSelectedStores(filterStores(selectedRetailers, value));
      }
    } else if (reason === 'remove-option') {
      if (availableRegions.length === selectedRegions.length) {
        setSelectedRegion(remove([...value], (i: any) => i.type !== 'All'));
      } else {
        setSelectedRegion(value);
        setSelectedStores(
          selectedStores.filter(
            (item: any) => item.region !== details.option.value,
          ),
        );
      }
    } else setSelectedRegion(value);
  };

  const checkSelected = (val: any) =>
    selectedStores.some((a: any) => a.store_id === val.store_id);

  const findValueIndex = (val: any) =>
    selectedStores.findIndex((a: any) => a.store_id === val.store_id);

  const onStoreSelect = (flag: boolean, val: any) => {
    const arr = flag
      ? [...selectedStores, val]
      : selectedStores.filter((_, i) => i !== findValueIndex(val));
    setSelectedStores(arr);
  };

  const saveChanges = () => {
    if (selectedStores.length > 0) {
      dispatch({
        type: 'CHANGE_SETTLEMENTS_SELECTED_RETAILER',
        payload: {
          title: startCase(
            selectedStores[0].store_type.replace('StoreType', ''),
          ),
          value: selectedStores[0].store_type,
        },
      });
      dispatch({ type: 'SET_SELECTED_RETAILERS', payload: selectedRetailers });
      dispatch({
        type: 'SET_ACTIVE_STORES',
        payload: lovesNamingConvention(selectedStores),
      });
      dispatch({ type: 'SET_SELECTED_REGIONS', payload: selectedRegions });
      dispatch({ type: 'INCLUDE_DEMO_STORES', payload: includeDemoStores });
      dispatch({
        type: 'INCLUDE_INACTIVE_STORES',
        payload: includeInactiveStores,
      });
      dispatch({
        type: 'CHANGE_PAGE_NUMBER',
        payload: { orders: 1, ccOrders: 1, kiosk: 1 },
      });
      eventBus.dispatch('storeSelectorUpdate', {
        regions: selectedRegions,
        retailers: selectedRetailers,
      });
      handleClose();
    } else showToast(true);
  };

  const filterStores = (selectedRetailers: any, selectedRegions: any) =>
    filterStoresByRegion(selectedRegions, selectedRetailers);

  const refreshStores = () =>
    storesApi.query({ v1: true, token: cookies.app_token, show_demo: true });

  const { isLoading, mutate: getStores } = useMutation(refreshStores, {
    onError: (serverError: any) => {
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: successData => {
      const storesData = get(successData, 'data.data', []).filter(
        (item: any) => !item.retailer.split(' ').includes('All'),
      );
      const namedStores = lovesNamingConvention(storesData);
      const regions = uniqBy(
        namedStores
          .map(
            (item: any) =>
              item.region && { title: item.region, value: item.region },
          )
          .filter((item: any) => item && item.value),
        'value',
      );
      const retailers = uniqBy(
        namedStores
          .map(
            (item: any) =>
              item.store_type && {
                title: startCase(item.store_type.replace('StoreType', '')),
                value: item.store_type,
              },
          )
          .filter((item: any) => item && item.value),
        'value',
      );
      dispatch({ type: 'SET_STORES', payload: namedStores });
      dispatch({ type: 'SET_REGIONS', payload: regions });
      dispatch({
        type: 'SET_RETAILERS',
        payload: retailers.sort((a: any, b: any) =>
          a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1,
        ),
      });
    },
  });

  useEffect(() => {
    setData(uniqBy(filterStoresByRegion(), 'store_id'));
  }, [
    selectedRetailers,
    selectedRegions,
    includeDemoStores,
    includeInactiveStores,
  ]);

  useEffect(() => {
    setSelectAllStores(selectedStores.length === filterStoresByRegion().length);
  }, [selectedStores]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const renderFilters = () => (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        id="retailers"
        options={availableRetailers}
        disableCloseOnSelect
        size="small"
        value={selectedRetailers}
        getOptionLabel={(option: any) => option?.title}
        onChange={onRetailerChange}
        renderOption={(option, { selected }) => {
          const indeterminate =
            selectedRetailers.length &&
            !selectedRetailers.some((item: any) => item.type === 'All');
          return (
            <SystemCheckbox
              color="primary"
              option={option}
              selected={selected}
              indeterminate={indeterminate}
            />
          );
        }}
        getOptionSelected={(option: any, value: any) =>
          option?.value === value?.value
        }
        popupIcon={<ArrowDownIcon />}
        className={classes.retailerInput}
        renderInput={params => (
          <TextField {...params} variant="outlined" label="Retailers" />
        )}
      />
      <Autocomplete
        multiple
        id="regions"
        options={availableRegions}
        disableCloseOnSelect
        size="small"
        value={selectedRegions}
        limitTags={2}
        getOptionLabel={(option: any) => option?.title}
        onChange={onRegionChange}
        renderOption={(option, { selected }) => {
          const indeterminate =
            selectedRegions.length &&
            !selectedRegions.some((item: any) => item.type === 'All');
          return (
            <SystemCheckbox
              color="primary"
              option={option}
              selected={selected}
              indeterminate={indeterminate}
            />
          );
        }}
        getOptionSelected={(option: any, value: any) =>
          option?.value === value?.value
        }
        popupIcon={<ArrowDownIcon />}
        className={classes.regionInput}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Region"
            style={{ maxHeight: 20 }}
          />
        )}
      />
    </>
  );

  return (
    <Box className={classes.root}>
      <DialogTitle className={classes.dialogTitle}>
        {isSmallScreen && (
          <Box className={classes.headerContainer}>
            <Box className={classes.backButton} onClick={handleClose}>
              <IconButton style={{ paddingLeft: 0 }}>
                <ArrowBackIcon style={{ color: '#000' }} />
              </IconButton>
            </Box>
            <Box className={classes.headerTitle}>Select Store</Box>
            <Box className={classes.refreshBtn}>
              <Button
                startIcon={isLoading ? <CircularProgress size={25} /> : <></>}
                color="primary"
                className={classes.refresh}
                onClick={() => getStores()}
              >
                <Hidden only={['md', 'lg', 'xl']}>
                  {!isLoading && (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.04911 12.6996C4.17098 12.6996 4.33348 12.659 4.45536 12.6184L8.96473 11.034C9.45223 10.8715 9.69598 10.3434 9.53348 9.85586C9.37098 9.36836 8.84286 9.12461 8.35536 9.28711L5.55223 10.2621C6.89286 6.76836 10.3054 4.41211 14.2054 4.41211C18.471 4.41211 22.1679 7.25586 23.2241 11.3184C23.346 11.8059 23.8335 12.0902 24.321 11.9684C24.8085 11.8465 25.0929 11.359 24.971 10.8715C23.7116 5.99648 19.2835 2.58398 14.1647 2.58398C9.61473 2.58398 5.59286 5.26523 3.92723 9.28711L3.07411 6.84961C2.91161 6.36211 2.38348 6.11836 1.89598 6.28086C1.40848 6.44336 1.16473 6.97148 1.32723 7.45898L2.87098 11.8871C3.11473 12.4152 3.56161 12.6996 4.04911 12.6996Z"
                        fill="#111928"
                      />
                      <path
                        d="M25.8648 21.1088L24.1992 16.7619C24.0773 16.4775 23.8742 16.2338 23.5898 16.1119C23.3054 15.99 22.9804 15.99 22.696 16.1119L18.2273 17.8182C17.7398 17.9807 17.5367 18.5088 17.6992 18.9963C17.8617 19.4838 18.3898 19.6869 18.8773 19.5244L21.6804 18.4682C20.0554 21.4338 16.8867 23.3838 13.3523 23.3838C9.33041 23.3838 5.79603 20.865 4.53666 17.1275C4.37416 16.64 3.84603 16.3963 3.39916 16.5588C2.91166 16.7213 2.66791 17.2494 2.83041 17.6963C4.33353 22.165 8.55853 25.1713 13.3523 25.1713C17.5367 25.1713 21.3148 22.8557 23.2242 19.2807L24.1585 21.7588C24.2804 22.1244 24.646 22.3275 25.0117 22.3275C25.1335 22.3275 25.2148 22.3275 25.3367 22.2463C25.8242 22.1244 26.0273 21.5963 25.8648 21.1088Z"
                        fill="#111928"
                      />
                    </svg>
                  )}
                </Hidden>
                <Hidden only={['xs', 'sm']}>Refresh</Hidden>
              </Button>
            </Box>
          </Box>
        )}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Input
            value={input}
            fullWidth={true}
            name="search"
            placeholder="Search"
            onChange={onChangeText}
            autoComplete="off"
            style={{
              marginRight: 15,
              padding: 8,
              position: 'relative',
              background: '#fff',
              borderRadius: 6,
              border: '1px solid #D1D5DB',
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              input ? (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      onChangeText({ target: { value: '' } });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              )
            }
          />
          {isSmallScreen && (
            <IconButton
              onClick={() => setFiltersVisible(!filtersVisible)}
              style={{ marginLeft: 8 }}
            >
              <ExpandMoreIcon
                style={{
                  transform: filtersVisible ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </IconButton>
          )}
          {!isSmallScreen && (
            <Button
              startIcon={isLoading ? <CircularProgress size={25} /> : <></>}
              color="primary"
              className={classes.refresh}
              onClick={() => getStores()}
            >
              <Hidden only={['md', 'lg', 'xl']}>
                {!isLoading && (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.04911 12.6996C4.17098 12.6996 4.33348 12.659 4.45536 12.6184L8.96473 11.034C9.45223 10.8715 9.69598 10.3434 9.53348 9.85586C9.37098 9.36836 8.84286 9.12461 8.35536 9.28711L5.55223 10.2621C6.89286 6.76836 10.3054 4.41211 14.2054 4.41211C18.471 4.41211 22.1679 7.25586 23.2241 11.3184C23.346 11.8059 23.8335 12.0902 24.321 11.9684C24.8085 11.8465 25.0929 11.359 24.971 10.8715C23.7116 5.99648 19.2835 2.58398 14.1647 2.58398C9.61473 2.58398 5.59286 5.26523 3.92723 9.28711L3.07411 6.84961C2.91161 6.36211 2.38348 6.11836 1.89598 6.28086C1.40848 6.44336 1.16473 6.97148 1.32723 7.45898L2.87098 11.8871C3.11473 12.4152 3.56161 12.6996 4.04911 12.6996Z"
                      fill="#111928"
                    />
                    <path
                      d="M25.8648 21.1088L24.1992 16.7619C24.0773 16.4775 23.8742 16.2338 23.5898 16.1119C23.3054 15.99 22.9804 15.99 22.696 16.1119L18.2273 17.8182C17.7398 17.9807 17.5367 18.5088 17.6992 18.9963C17.8617 19.4838 18.3898 19.6869 18.8773 19.5244L21.6804 18.4682C20.0554 21.4338 16.8867 23.3838 13.3523 23.3838C9.33041 23.3838 5.79603 20.865 4.53666 17.1275C4.37416 16.64 3.84603 16.3963 3.39916 16.5588C2.91166 16.7213 2.66791 17.2494 2.83041 17.6963C4.33353 22.165 8.55853 25.1713 13.3523 25.1713C17.5367 25.1713 21.3148 22.8557 23.2242 19.2807L24.1585 21.7588C24.2804 22.1244 24.646 22.3275 25.0117 22.3275C25.1335 22.3275 25.2148 22.3275 25.3367 22.2463C25.8242 22.1244 26.0273 21.5963 25.8648 21.1088Z"
                      fill="#111928"
                    />
                  </svg>
                )}
              </Hidden>
              <Hidden only={['xs', 'sm']}>Refresh</Hidden>
            </Button>
          )}
        </Box>

        {filtersVisible && (
          <>
            <Hidden only={['xs', 'sm', 'md']}>
              <Box className={classes.filterLayoutRow}>{renderFilters()}</Box>
            </Hidden>
            <Hidden only={['lg', 'xl']}>
              <Box className={classes.filterLayoutCol}>{renderFilters()}</Box>
            </Hidden>
            {(email && email.includes('mishipay')) ||
            username.includes('-demo-user') ? (
              <>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Typography className={classes.includeText}>
                    Include:
                  </Typography>
                </Hidden>
                <Box className={classes.includeStoresContainer}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Hidden only={['xs', 'sm']}>
                      <Typography>Include:</Typography>
                    </Hidden>
                    <Checkbox
                      name="demo stores"
                      checked={includeDemoStores}
                      onChange={() => {
                        onIncludeToggle(IncludeTypes.DEMO);
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Typography>Demo stores</Typography>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      name="include inactive stores"
                      checked={includeInactiveStores}
                      onChange={() => {
                        onIncludeToggle(IncludeTypes.INACTIVE);
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Typography>Inactive stores</Typography>
                  </Box>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <Hidden only={['xs', 'sm']}>
          <Box className={classes.filterLayoutRow}>{renderFilters()}</Box>
          {(email && email.includes('mishipay')) ||
          username.includes('-demo-user') ? (
            <>
              <Hidden only={['md', 'lg', 'xl']}>
                <Typography className={classes.includeText}>
                  Include:
                </Typography>
              </Hidden>
              <Box className={classes.includeStoresContainer}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Hidden only={['xs', 'sm']}>
                    <Typography>Include:</Typography>
                  </Hidden>
                  <Checkbox
                    name="demo stores"
                    checked={includeDemoStores}
                    onChange={() => {
                      onIncludeToggle(IncludeTypes.DEMO);
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <Typography>Demo stores</Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    name="include inactive stores"
                    checked={includeInactiveStores}
                    onChange={() => {
                      onIncludeToggle(IncludeTypes.INACTIVE);
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <Typography>Inactive stores</Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Hidden>
      </DialogTitle>

      <DialogContent
        dividers={true}
        className={classes.dialogContent}
        style={{ maxHeight: filtersVisible ? 400 : 480 }}
      >
        {data && data.length ? (
          <List className={classes.list}>
            <ListItem
              onClick={() => {
                setSelectAllStores(!selectAllStores);
                setSelectedStores(!selectAllStores ? data : []);
              }}
              button
              className={classes.listItem}
            >
              <Checkbox
                color="primary"
                checked={selectAllStores}
                onChange={(event: any) => {
                  setSelectAllStores(event?.target.checked);
                  setSelectedStores(event?.target.checked ? data : []);
                }}
                inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                style={{ marginRight: 8 }}
              />
              <Typography className={classes.listItemText}>
                All Stores ({data.length})
              </Typography>
            </ListItem>
            {data.slice(0, itemsToShow).map((item: any, index: number) => {
              let status = [];
              item.demo && status.push('DEMO');
              !item.active && status.push('INACTIVE');
              return (
                <Box component="div" key={index}>
                  <ListItem
                    onClick={() => {
                      onStoreSelect(!checkSelected(item), item);
                    }}
                    button
                    key={index}
                    className={classes.listItem}
                  >
                    <Checkbox
                      color="primary"
                      checked={checkSelected(item)}
                      onChange={() => onStoreSelect(!checkSelected(item), item)}
                      inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography className={classes.listItemText}>
                      {item.retailer}
                    </Typography>
                    <Typography className={classes.listItemText} style={{ marginLeft: item.rfid === 1 ? 8 : 0 }}>
                      {item.rfid === 1 ? '(RFID)' : ''}
                    </Typography>
                    <Typography className={classes.listItemTextDemo}>
                      {status.length > 0 ? status.join(' | ') : ''}
                    </Typography>
                  </ListItem>
                  <Divider light />
                </Box>
              );
            })}
            {itemsToShow < data.length && (
              <div
                ref={loadMoreRef}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 16,
                }}
              >
                <Button onClick={handleLoadMore} color="primary">
                  Load More
                </Button>
              </div>
            )}
          </List>
        ) : (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>
              No stores available. Clear filters and try again.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.staticFooter}>
        <Box className={classes.clearButton}>
          <Button
            onClick={clearAll}
            color={'secondary'}
            className={classes.clear}
            style={{ textTransform: 'none', width: 100 }}
          >
            Clear All
          </Button>
          <Box className={classes.actionContainer}>
            <Button onClick={handleClose} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button
              onClick={saveChanges}
              color="primary"
              variant="contained"
              className={classes.selectedStoresButton}
            >
              Select Stores{' '}
              {selectedStores.length ? '( ' + selectedStores.length + ' )' : ''}
            </Button>
          </Box>
        </Box>
      </DialogActions>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={toast}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message="Please select a store"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseToast}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      {success && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="success"
          >
            {intl.formatMessage({ id: 'updateStores' })}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(StoreSelector);
